// 
import React, { Component } from 'react';
import classnames from 'classnames';

import Button from '../../buttons/Base';
import { clickableDivProps } from '../../../lib/accessibility_helpers';

import Copy from './copySvg';
import styles from './styles.module.scss';



class CopyText extends Component {
	constructor(props) {
		super(props);

		this.state = { copied: false };
	}

	copy = () => {
		const textField = document.createElement('textarea');
		textField.innerText = this.props.link || this.props.text;

		if (document.body) {
			document.body.appendChild(textField);
			textField.select();
			document.execCommand('copy');
			textField.remove();
		}

		this.setState({ copied: true });

		if (this.props.callback) {
			this.props.callback();
		}
	};

	get content() {
		if (this.props.link) {
			return (
				<a href={this.props.link} target="__blank" rel="noreferrer">
					{this.props.link}
				</a>
			);
		}

		return <span className={styles.text}>{this.props.text}</span>;
	}

	get copyButton() {
		return (
			<Button
				text={this.props.text || this.props.link}
				reverse={this.props.reverse}
				onClick={this.copy}
				ariaLabel={this.props.ariaLabel}
			/>
		);
	}

	render() {
		if (this.props.button) {
			return this.copyButton;
		}

		const label = this.state.copied ? 'copied' : 'copy';

		return (
			<div className={styles.linkWrapper}>
				{this.content}
				<div
					className={classnames(styles.copy, {
						[styles.copied]: this.state.copied,
					})}
					onClick={this.copy}
					{...clickableDivProps({ onClick: this.copy })}
					aria-label={this.props.ariaLabel}
					aria-describedby="copy-tooltip"
				>
					<div
						id="copy-tooltip"
						role="tooltip"
						aria-label={label}
						aria-live="polite"
						className={styles.tooltip}
					>
						{label}
					</div>
					<Copy />
				</div>
			</div>
		);
	}
}

export default CopyText;
