// 
import React from 'react';

const Copy = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="17"
		height="20"
		viewBox="0 0 17 20"
	>
		<path
			fill="#33534C"
			fillRule="nonzero"
			d="M2 0C.898 0 0 .898 0 2v13h2V2h10V0H2zm4 4c-1.102 0-2 .898-2 2v12c0 1.102.898 2 2 2h9c1.102 0 2-.898 2-2V6c0-1.102-.898-2-2-2H6zm0 2h9v12H6V6z"
		/>
	</svg>
);

export default Copy;

